import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image_mobile = require("../../../assets/img/blogs/enhance_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/enhance_blog_header.png");
const section_1 = require("../../../assets/img/blogs/enhance_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/enhance_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/enhance_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/enhance_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/enhance_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/enhance_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/enhance_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/enhance_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/enhance_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/enhance_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/enhance_blog_img_11.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Boost Collaboration & Engagement: Workativ + MS Teams"
        description="Get Workativ to use GenAI inside MS Teams to foster effective collaboration and engagement to allow your people to do their best work and enhance employee experience."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt enhance_blog_header_upt ${
              isSmall ? "align-items-start" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home  color-black line-height-18">
                    Enhance Employee Collaboration and Engagement with Workativ
                    and MS Teams
                  </h1>
                ) : (
                  <h1 className="font-page-header-blog-gen-ai color-black">
                    Enhance Employee
                    <br /> Collaboration and
                    <br /> Engagement with
                    <br /> Workativ and MS Teams
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why employee collaboration and engagement is important?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Employee experience challenges and solutions
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How Workativ works with MS Teams?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How to enhance employee collaboration and engagement with
                  Workativ and MS Teams
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. The benefits of Workativ and MS Teams
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Start your employee experience journey with Workativ and MS
                  Teams
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Effective collaboration and engagement
                </span>{" "}
                are essential to allowing your people to do their best work. As
                a result, companies can ensure{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  excellent
                </span>{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  employee experience.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Under the fold, delivering employee experience may be a
                difficult job for most companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A survey by{" "}
                <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html">
                  PwC{" "}
                </a>{" "}
                found that companies are twice as interested in digital
                investments but struggle to adapt to the future.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a core part of employee experience, technology plays a
                crucial part.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.oracle.com/human-capital-management/employee-experience/employee-experience-challenges/">
                  Oracle
                </a>{" "}
                in their article narrated the views of employees, wherein they
                believe that employee experience must align with their values,
                be meaningful, and be rewarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  The U.S. Bureau of Labor Statistics
                </span>{" "}
                reported about 4 million workers quitting their jobs in 2021.
                This trend continues to deepen, leading companies to fill these
                positions quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees, however, want to focus on employee experience to
                align with their expectations. Simultaneously, employers find it
                significant in driving productivity, efficiency, satisfaction,
                and growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As employee experience is an integral part of a company’s
                growth,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                ensures effective collaborations and engagement through
                end-to-end employee interaction, thus facilitating growth and
                satisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ with Generative AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge GPT
                </a>{" "}
                as a ChatGPT-like interface for MS Teams helps facilitate
                communications and collaboration, solve queries, and ensure that
                every question is answered in the simplest manner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s deep dive to learn about the best ways Workativ enforces
                to enhance employee collaboration and engagement for the
                ultimate employee experience with MS Teams and Generative AI.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why employee collaboration and engagement is important?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Significance of MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Numerous intricate internal processes can combine to make things
                challenging to handle and impact the employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, effective employee collaboration and engagement improve
                employee experience, which translates into company growth.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Delivering services for the employee lifecycle journey
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee experience encompasses each step of the interactions
                and touchpoints where employees seek help from HR, IT, or
                stakeholders to find answers to their questions, solve problems,
                do their best work, and increase productivity. tomers.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Removing friction from the employee lifecycle journey
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The effectiveness of collaboration and engagement also removes
                the friction from the employee experience path, providing
                all-encompassing services to each employee, irrespective of
                their location.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As an HR leader or IT support manager, it is essential to ensure
                collaboration and engagement for your employees to ensure
                employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, a powerful tool or the right enterprise technology is
                essential to facilitate this critical objective.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, a conversational AI platform, acts as an enterprise
                tool for comprehensive collaboration and engagement and
                facilitates employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Employee experience challenges and solutions
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprises or SMBs seem enthusiastic about new technology
                innovation and keen to unlock their potential.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The same enthusiasm looks very strong for Generative AI
                Adoption. However, embracing does not help unless one can adjust
                to the change and implement the right approach to using this new
                technology. As a result, collaboration and engagement can
                suffer, causing employee frustration and compromising their
                experiences.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are many hurdles to employee experience that hinge upon
                collaboration and engagement.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                54% of employees need significant training or learn digital
                skills
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Reduction of employee training costs through MS Teams and GenAI chatbot"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The{" "}
                  <a href="https://www.weforum.org/publications/the-future-of-jobs-report-2018/">
                    World Economic Forum
                  </a>{" "}
                  stated that 54% of employees need training and 35% of them
                  need 6 months of effort. Unfortunately, most companies lack
                  adequate resources to train their employees, although today’s
                  workforce is curious to learn new technology.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In a survey,{" "}
                  <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html">
                    PwC
                  </a>{" "}
                  says only 50% of staff and 64% of managers can leverage
                  adequate resources to reskill and learn new technology.
                  Unfortunately, the rest of the people face a deficient budget,
                  or companies neglect the need for tech-savvy training.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Both surveys indicate that employee development and skills are
                important to learn new technology. Simultaneously, they require
                significant resources, which most companies lack.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ{" "}
                </a>
                makes learning and adjusting to new enterprise technology easy
                by integrating its{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  no-code conversational AI platform
                </span>{" "}
                inside{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams.
                </a>{" "}
                It boosts collaboration and engagement for small to complex
                queries and offers real-time problem resolutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The integration ensures that{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  users do not need prior training or guidance to learn to use
                </span>{" "}
                the platform, thus fostering employee convenience and cost
                savings.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                48% of companies experience tech performance issues every day
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="the convenience of issue handling with MS Teams and GenAI chatbot"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Your employees experience technology performance problems
                  every day. These problems involve desktop or laptop issues,
                  including app downtime or access issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  All these are common problems, yet{" "}
                  <a href="https://www.computerworld.com/article/1607213/tech-performance-issues-plague-it-and-businesses.html">
                    48% of companies face tech performance issues every day.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unfortunately, only problems presumed to be critical or of
                  high importance receive attention, while everyday issues
                  become frequent and severe for companies.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A technology platform with unified tools is convenient to help
                your people tackle these issues autonomously and resolve them as
                quickly as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ uses{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Generative AI or its proprietary tool Knowledge AI&nbsp;
                </a>
                within its conversational AI platform to help improve knowledge
                search by turning your knowledge bases into ChatGPT-like search
                experiences inside your MS Teams. The integration with ITSM
                platforms and other enterprise tools, such as Google Drive,
                Notion, Confluence, IAM platforms, HR platforms, etc., can
                easily fetch information directly inside the familiar chat
                window in Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This gives your people a better way to get a unified view of a
                wide array of tools, interact with them efficiently, remove
                silos, and resolve problems at scale, and gain productivity.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Only 43% support change initiatives in the hybrid ways of
                working
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" MS Teams and GenAI chatbot support seamless change initiatives"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Organizations continue to refine digital transformation
                  through change initiatives to adjust to hybrid work settings.
                  As per Gartner,{" "}
                  <a href="https://www.gartner.com/en/webinar/462138/1090629">
                    the willingness to support change initiatives has dropped
                    from 74% in 2016 to just 43% in 2022.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  The average employee has experienced at least 10 new
                  technology changes in the last few years, especially
                  post-COVID. However, employees are less likely to adjust to
                  change. Certainly, change fatigue is a new concern for HR.
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is no surprise that employees are highly susceptible to
                  losing patience with change initiatives with regard to
                  Generative AI-related changes. Unfortunately, this hurdle can
                  deprive organizations of Generative AI gains.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                The success of change initiatives lies in making the adoption of
                new tools easy and convenient for your people.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Embedding Generative AI into workflows is a flexible way to
                allow your people to gain automation benefits, streamline
                workflows, and improve internal communications and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using a{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  no-code SaaS platform,
                </a>{" "}
                Workativ lets you embed LLMs into workflows and apply Generative
                AI search as you interact with MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Hence, Workativ accelerates the pace of adoption of new
                technology through MS Teams, allowing you to leverage GenAI
                benefits to their fullest potential and help with change
                initiatives without fatigue. .
              </p>
              <h3 className="font-section-sub-header-small color-black">
                53% of staff deny leveraging technology of their preferences
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="MS Teams and GenAI chatbot reduces the complexity of working with industry tools"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Legacy tech systems work in silos, are rigid, and are not
                  intuitive, prompting immediate replacement.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unfortunately, even though{" "}
                  <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html#insight2">
                    {" "}
                    90% of executives state they choose their tech based on what
                    their people prefer,
                  </a>{" "}
                  53% of people disagree with it, causing digital transformation
                  hurdles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  For example, ITSM platforms with conventional modules may seem
                  critical to handle for collaboration and engagement. Employees
                  may seek something easy and fast to use to communicate and get
                  answers.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                When it is MS Teams as a conversational AI bot that harnesses
                the power of Generative AI, there is no longer a trade-off
                between you and your people.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can give your people what they are familiar with already and
                move to digital transformation quickly while eliminating the
                pains of legacy tech frameworks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can easily turn ITSM workflows into your MS Teams
                interactions with Workativ,
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  encourage adoption at speed,
                </span>{" "}
                and make collaboration and engagement a seamless approach for
                your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Workativ works with MS Teams?
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Improvement of collaboration and engagement with MS Teams and GenAI chatbot 
                "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of Workativ is to improve collaboration and
                engagement between your people and service desks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Through effective employee engagement and collaboration, it is
                easy to solve employee issues and improve employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ has an easy-to-use, no-code interface that allows
                anyone in your organization to handle its features and implement
                appropriate workflows to manage{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams conversations for ITSM or service desks.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps to integrate Workativ with MS Teams—
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>Sign up with Workativ</li>
                <li>Evaluate your workplace scenarios for use cases</li>
                <li>Prepare your knowledge bases</li>
                <li>Implement Knowledge AI</li>
                <li>Create and activate your workflows</li>
                <li>Add ITSM</li>
                <li>Deploy your bot on your MS Teams</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you set up your MS Teams bot for any of your ITSM
                platforms, you can automate and streamline any kind of tasks and
                improve employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to enhance employee collaboration and engagement with
                Workativ and MS Teams
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ facilitates end-to-end employee collaboration and
                engagement from onboarding to offboarding. Therefore, Workativ
                allows you to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  create as many use cases as you need,
                </span>{" "}
                improve communications, perform action, and resolve problems to
                improve productivity at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                You can use Workativ as your AI copilot for MS Teams and
                transform the way your people search information, find answers,
                and do their best work.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Dynamic notifications for uninterrupted access
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Real-time notifications on MS Teams and Generative AI  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Enterprise tools can restrict access when a license expires. You
                can automate a notification workflow to alert your manager and
                take the necessary steps to refill the application package and
                continue the work.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Alert for routine maintenance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Inform your employees about upcoming routine maintenance. This
                will help them plan ahead and adjust to system downtime.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                3. Reminders for compliance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Set up workflows to remind your employees about a form
                submission. It can involve your new hires submitting their
                documents, and old employees submitting their feedback about
                certain changes or views about new work policies.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                4. App access request
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your employees to request company-wide apps for their
                specific tasks. For example, a finance team can ask for access
                to the Excel suite.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. New employee onboarding
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Simplify employee onboarding using MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Have workflows in place to send messages to new employees
                welcoming them or asking them to perform necessary compliance
                tasks.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                6. Remove app access
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                After offboarding happens, you can automatically remove app
                access using workflows and accelerate HR operations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                7. Allow leave requests
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your employee to ask for leave balances and apply for
                leave automatically with the manager’s approval.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                8. Reimbursement request
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let you people raise reimbursement queries with a conversational
                AI platform and claim it without anyone’s assistance.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                9. Fix technical issue
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Help your employees fix common technical issues with their apps
                by using Knowledge AI-powered knowledge bases.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                10. Password resets
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Password reset automation with MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your people to tackle ‘forget password’ problems by
                collaborating with the MS Teams bot and sending a request to fix
                it.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                11. VPN settings
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To improve the security of your company-wide devices, have all
                attributes ready to ensure VPN settings are easily set up
                autonomously using Workativ Knowledge AI.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                12. Agent handover
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Seamless agent handover with MS Teams and GenAI chatbot"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If your knowledge base articles offer limited help, offer
                instant workflow to connect with agents or service desk support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using Workativ and MS Teams, you can harness KnowledgeAI to
                create custom use cases for your company-specific problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This would ultimately help your employees solve their problems
                through powerful collaborations and engagement and improve their
                experiences.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The benefits of Workativ and MS Teams
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Benefits of MS Teams and GenAI chatbots like Workativ "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As you look to boost employee collaboration and engagement,
                Workativ enhances the capabilities of MS Teams and offers
                exciting benefits to transform employee experience.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Easy transition from the legacy framework to a digitized work
                  setting
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ combines modern technological advances with the
                powerful integration of Knowledge GPT or Knowledge AI for MS
                Teams. It helps you move beyond legacy architecture and easily
                leverage modern features to get a competitive advantage.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Faster adoption of new technology without training or
                  upskilling
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your team is familiar with MS Teams, so they can learn all the
                attributes of raising questions, getting answers, and solving
                problems.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>In-app ticket creation</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You can use MS Teams to create a ticket for a critical issue and
                easily get human assistance without logging in and out of your
                traditional ITSM platform.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Unified collaboration and coordination for service desk tasks
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With Workativ, you can keep track of service desk history in one
                place inside MS Teams, allowing you to prioritize tickets and
                address problems at speed.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Data-driven insights for collaboration improvement</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ lets you harness rich insights from the platform. This
                is a treasure trove for your service desk teams to analyze
                loopholes and make improvements to future collaborations and
                solve problems steadily.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Self-service augmentation</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The Knowledge AI integration turns knowledge bases into
                ChatGPT-like responses. As a result, your people get
                consolidated, accurate, and contextual information to solve
                their problems, while saving time to get back to work faster.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Employee engagement and satisfaction</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT or LLM-driven answers solve common problems at scale,
                removing the need to connect to service desks and wait for your
                turn. The swift information delivery satisfies employees and
                encourages them to adopt self-service at scale.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Agent flexibility and convenience</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ prioritizes agent flexibility by allowing them to use a
                built-in shared live inbox that combines all ticket history into
                one single pane and pinpoints the high-priority tickets, tickets
                in queue, open tickets, and closed tickets. Besides, AI
                suggestions help them answer employees quickly and in real-time.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Compliance with the shift-left strategy</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Thanks to the hyper-automation of self-service and the
                integration of Knowledge AI, Workativ accelerates knowledge
                search and reduces time to mitigate issues using knowledge bases
                at Level 0. With no human agents needed, Workativ helps you
                comply with the shift-left strategy and save costs.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>XLAs for ITIL compliance</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ improves data-driven automation and analytics, giving
                in-depth views into ITSM performance and opportunities for
                improving existing workflows. As a result, issues are less
                repetitive, frequent, and severe, fostering proactive service
                desks and transitioning to an XLA approach.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Start your employee experience journey with Workativ and MS
                Teams
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ has all-encompassing features that help you build a
                comprehensive and powerful collaboration and engagement
                ecosystem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is easy and fast for you to turn MS Teams into a familiar
                platform where your team members can connect seamlessly and
                effortlessly to find answers for common to complex problems,
                solve them autonomously, and get back to work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of it, MS Teams also lets you apply LLMs to search
                queries and find contextual answers in real-time to reduce
                downtime in minimal time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, you can build a powerful collaboration and
                engagement platform for employee support and effectively harness
                the power of Generative AI for ultimate benefits. Overall, you
                can foster employee experience and foster your company’s growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you need assistance in building your GenAI-powered
                collaboration and engagement tool with MS Teams, Workativ can
                help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule your demo today.{" "}
                </a>
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. How can you extend the collaboration and engagement
                capabilities for your employees on MS Teams?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams is a powerful collaboration and engagement tool. You
                normally use it to share files, chat, meet with teams, and share
                group messages, among other things.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, you can also use MS Teams to extend automation for an
                expandable level of collaboration and engagement to improve the
                employee experience by integrating{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  a conversational AI platform like Workativ
                </span>{" "}
                powered by Generative AI. This allows MS Teams to offer its
                users amazing features to find information, do their best work,
                and seek human assistance for critical issues.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How does Workativ help with change initiatives for digital
                transformation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital transformation entails introducing enterprise tools for
                workers to allow them to work efficiently. But change fatigue is
                a new concern. Workativ can help with change initiatives with
                its intuitive interface, which is easy to use for enterprise
                collaboration, engagement, and problem-solving.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Does building MS Teams with a conversational AI platform like
                Workativ involve developer assistance?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code SaaS platform, allowing you to work around
                its intuitive interface to build workflows and deploy them to MS
                Teams through integration. Using DIY features, you can easily
                start your employee experience journey on Workativ and turn MS
                Teams into a powerhouse of employee collaboration and engagement
                driven by GenAI. Hence, it requires no developer help and
                involves high costs.
              </p>
              <h3 className="font-section-sub-header-small">
                4. What are three significant use cases of MS Teams for
                collaboration and engagement other than usual usage?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When combined with Workativ and its GPT platform Knowledge AI,
                MS Teams helps boost information discovery, thus automating
                collaboration and engagement efficiently. These use cases
                include resolving password resets, leave applications, and app
                access requests among others.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why employee collaboration and engagement is important?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Employee experience challenges and solutions
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How Workativ works with MS Teams?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How to enhance employee collaboration and engagement with
                    Workativ and MS Teams
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. The benefits of Workativ and MS Teams
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Start your employee experience journey with Workativ and
                    MS Teams
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Effective collaboration and engagement
                </span>{" "}
                are essential to allowing your people to do their best work. As
                a result, companies can ensure{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  excellent
                </span>{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  employee experience.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Under the fold, delivering employee experience may be a
                difficult job for most companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A survey by{" "}
                <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html">
                  PwC{" "}
                </a>{" "}
                found that companies are twice as interested in digital
                investments but struggle to adapt to the future.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a core part of employee experience, technology plays a
                crucial part.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.oracle.com/human-capital-management/employee-experience/employee-experience-challenges/">
                  Oracle
                </a>{" "}
                in their article narrated the views of employees, wherein they
                believe that employee experience must align with their values,
                be meaningful, and be rewarding.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  The U.S. Bureau of Labor Statistics
                </span>{" "}
                reported about 4 million workers quitting their jobs in 2021.
                This trend continues to deepen, leading companies to fill these
                positions quickly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees, however, want to focus on employee experience to
                align with their expectations. Simultaneously, employers find it
                significant in driving productivity, efficiency, satisfaction,
                and growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As employee experience is an integral part of a company’s
                growth,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                ensures effective collaborations and engagement through
                end-to-end employee interaction, thus facilitating growth and
                satisfaction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ with Generative AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge GPT
                </a>{" "}
                as a ChatGPT-like interface for MS Teams helps facilitate
                communications and collaboration, solve queries, and ensure that
                every question is answered in the simplest manner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s deep dive to learn about the best ways Workativ enforces
                to enhance employee collaboration and engagement for the
                ultimate employee experience with MS Teams and Generative AI.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why employee collaboration and engagement is important?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Significance of MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Numerous intricate internal processes can combine to make things
                challenging to handle and impact the employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, effective employee collaboration and engagement improve
                employee experience, which translates into company growth.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Delivering services for the employee lifecycle journey
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee experience encompasses each step of the interactions
                and touchpoints where employees seek help from HR, IT, or
                stakeholders to find answers to their questions, solve problems,
                do their best work, and increase productivity. tomers.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Removing friction from the employee lifecycle journey
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The effectiveness of collaboration and engagement also removes
                the friction from the employee experience path, providing
                all-encompassing services to each employee, irrespective of
                their location.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As an HR leader or IT support manager, it is essential to ensure
                collaboration and engagement for your employees to ensure
                employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, a powerful tool or the right enterprise technology is
                essential to facilitate this critical objective.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, a conversational AI platform, acts as an enterprise
                tool for comprehensive collaboration and engagement and
                facilitates employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Employee experience challenges and solutions
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprises or SMBs seem enthusiastic about new technology
                innovation and keen to unlock their potential.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The same enthusiasm looks very strong for Generative AI
                Adoption. However, embracing does not help unless one can adjust
                to the change and implement the right approach to using this new
                technology. As a result, collaboration and engagement can
                suffer, causing employee frustration and compromising their
                experiences.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are many hurdles to employee experience that hinge upon
                collaboration and engagement.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                54% of employees need significant training or learn digital
                skills
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Reduction of employee training costs through MS Teams and GenAI chatbot"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The{" "}
                  <a href="https://www.weforum.org/publications/the-future-of-jobs-report-2018/">
                    World Economic Forum
                  </a>{" "}
                  stated that 54% of employees need training and 35% of them
                  need 6 months of effort. Unfortunately, most companies lack
                  adequate resources to train their employees, although today’s
                  workforce is curious to learn new technology.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In a survey,{" "}
                  <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html">
                    PwC
                  </a>{" "}
                  says only 50% of staff and 64% of managers can leverage
                  adequate resources to reskill and learn new technology.
                  Unfortunately, the rest of the people face a deficient budget,
                  or companies neglect the need for tech-savvy training.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Both surveys indicate that employee development and skills are
                important to learn new technology. Simultaneously, they require
                significant resources, which most companies lack.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ{" "}
                </a>
                makes learning and adjusting to new enterprise technology easy
                by integrating its{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  no-code conversational AI platform
                </span>{" "}
                inside{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams.
                </a>{" "}
                It boosts collaboration and engagement for small to complex
                queries and offers real-time problem resolutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The integration ensures that{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  users do not need prior training or guidance to learn to use
                </span>{" "}
                the platform, thus fostering employee convenience and cost
                savings.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                48% of companies experience tech performance issues every day
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="the convenience of issue handling with MS Teams and GenAI chatbot"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Your employees experience technology performance problems
                  every day. These problems involve desktop or laptop issues,
                  including app downtime or access issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  All these are common problems, yet{" "}
                  <a href="https://www.computerworld.com/article/1607213/tech-performance-issues-plague-it-and-businesses.html">
                    48% of companies face tech performance issues every day.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unfortunately, only problems presumed to be critical or of
                  high importance receive attention, while everyday issues
                  become frequent and severe for companies.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                A technology platform with unified tools is convenient to help
                your people tackle these issues autonomously and resolve them as
                quickly as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ uses{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Generative AI or its proprietary tool Knowledge AI&nbsp;
                </a>
                within its conversational AI platform to help improve knowledge
                search by turning your knowledge bases into ChatGPT-like search
                experiences inside your MS Teams. The integration with ITSM
                platforms and other enterprise tools, such as Google Drive,
                Notion, Confluence, IAM platforms, HR platforms, etc., can
                easily fetch information directly inside the familiar chat
                window in Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This gives your people a better way to get a unified view of a
                wide array of tools, interact with them efficiently, remove
                silos, and resolve problems at scale, and gain productivity.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Only 43% support change initiatives in the hybrid ways of
                working
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" MS Teams and GenAI chatbot support seamless change initiatives"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Organizations continue to refine digital transformation
                  through change initiatives to adjust to hybrid work settings.
                  As per Gartner,{" "}
                  <a href="https://www.gartner.com/en/webinar/462138/1090629">
                    the willingness to support change initiatives has dropped
                    from 74% in 2016 to just 43% in 2022.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  The average employee has experienced at least 10 new
                  technology changes in the last few years, especially
                  post-COVID. However, employees are less likely to adjust to
                  change. Certainly, change fatigue is a new concern for HR.
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is no surprise that employees are highly susceptible to
                  losing patience with change initiatives with regard to
                  Generative AI-related changes. Unfortunately, this hurdle can
                  deprive organizations of Generative AI gains.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                The success of change initiatives lies in making the adoption of
                new tools easy and convenient for your people.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Embedding Generative AI into workflows is a flexible way to
                allow your people to gain automation benefits, streamline
                workflows, and improve internal communications and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using a{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  no-code SaaS platform,
                </a>{" "}
                Workativ lets you embed LLMs into workflows and apply Generative
                AI search as you interact with MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Hence, Workativ accelerates the pace of adoption of new
                technology through MS Teams, allowing you to leverage GenAI
                benefits to their fullest potential and help with change
                initiatives without fatigue. .
              </p>
              <h3 className="font-section-sub-header-small color-black">
                53% of staff deny leveraging technology of their preferences
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="MS Teams and GenAI chatbot reduces the complexity of working with industry tools"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Legacy tech systems work in silos, are rigid, and are not
                  intuitive, prompting immediate replacement.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unfortunately, even though{" "}
                  <a href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/tech-at-work.html#insight2">
                    {" "}
                    90% of executives state they choose their tech based on what
                    their people prefer,
                  </a>{" "}
                  53% of people disagree with it, causing digital transformation
                  hurdles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  For example, ITSM platforms with conventional modules may seem
                  critical to handle for collaboration and engagement. Employees
                  may seek something easy and fast to use to communicate and get
                  answers.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                Solutions:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                When it is MS Teams as a conversational AI bot that harnesses
                the power of Generative AI, there is no longer a trade-off
                between you and your people.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can give your people what they are familiar with already and
                move to digital transformation quickly while eliminating the
                pains of legacy tech frameworks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can easily turn ITSM workflows into your MS Teams
                interactions with Workativ,
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  encourage adoption at speed,
                </span>{" "}
                and make collaboration and engagement a seamless approach for
                your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Workativ works with MS Teams?
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Improvement of collaboration and engagement with MS Teams and GenAI chatbot 
                "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of Workativ is to improve collaboration and
                engagement between your people and service desks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Through effective employee engagement and collaboration, it is
                easy to solve employee issues and improve employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ has an easy-to-use, no-code interface that allows
                anyone in your organization to handle its features and implement
                appropriate workflows to manage{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  MS Teams conversations for ITSM or service desks.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps to integrate Workativ with MS Teams—
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>Sign up with Workativ</li>
                <li>Evaluate your workplace scenarios for use cases</li>
                <li>Prepare your knowledge bases</li>
                <li>Implement Knowledge AI</li>
                <li>Create and activate your workflows</li>
                <li>Add ITSM</li>
                <li>Deploy your bot on your MS Teams</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you set up your MS Teams bot for any of your ITSM
                platforms, you can automate and streamline any kind of tasks and
                improve employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to enhance employee collaboration and engagement with
                Workativ and MS Teams
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ facilitates end-to-end employee collaboration and
                engagement from onboarding to offboarding. Therefore, Workativ
                allows you to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  create as many use cases as you need,
                </span>{" "}
                improve communications, perform action, and resolve problems to
                improve productivity at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                You can use Workativ as your AI copilot for MS Teams and
                transform the way your people search information, find answers,
                and do their best work.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                1. Dynamic notifications for uninterrupted access
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Real-time notifications on MS Teams and Generative AI  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Enterprise tools can restrict access when a license expires. You
                can automate a notification workflow to alert your manager and
                take the necessary steps to refill the application package and
                continue the work.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                2. Alert for routine maintenance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Inform your employees about upcoming routine maintenance. This
                will help them plan ahead and adjust to system downtime.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                3. Reminders for compliance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Set up workflows to remind your employees about a form
                submission. It can involve your new hires submitting their
                documents, and old employees submitting their feedback about
                certain changes or views about new work policies.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                4. App access request
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your employees to request company-wide apps for their
                specific tasks. For example, a finance team can ask for access
                to the Excel suite.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                5. New employee onboarding
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Simplify employee onboarding using MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Have workflows in place to send messages to new employees
                welcoming them or asking them to perform necessary compliance
                tasks.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                6. Remove app access
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                After offboarding happens, you can automatically remove app
                access using workflows and accelerate HR operations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                7. Allow leave requests
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your employee to ask for leave balances and apply for
                leave automatically with the manager’s approval.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                8. Reimbursement request
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let you people raise reimbursement queries with a conversational
                AI platform and claim it without anyone’s assistance.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                9. Fix technical issue
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Help your employees fix common technical issues with their apps
                by using Knowledge AI-powered knowledge bases.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                10. Password resets
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Password reset automation with MS Teams and GenAI chatbot "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Allow your people to tackle ‘forget password’ problems by
                collaborating with the MS Teams bot and sending a request to fix
                it.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                11. VPN settings
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To improve the security of your company-wide devices, have all
                attributes ready to ensure VPN settings are easily set up
                autonomously using Workativ Knowledge AI.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                12. Agent handover
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Seamless agent handover with MS Teams and GenAI chatbot"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If your knowledge base articles offer limited help, offer
                instant workflow to connect with agents or service desk support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using Workativ and MS Teams, you can harness KnowledgeAI to
                create custom use cases for your company-specific problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This would ultimately help your employees solve their problems
                through powerful collaborations and engagement and improve their
                experiences.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The benefits of Workativ and MS Teams
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Benefits of MS Teams and GenAI chatbots like Workativ "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As you look to boost employee collaboration and engagement,
                Workativ enhances the capabilities of MS Teams and offers
                exciting benefits to transform employee experience.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Easy transition from the legacy framework to a digitized work
                  setting
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ combines modern technological advances with the
                powerful integration of Knowledge GPT or Knowledge AI for MS
                Teams. It helps you move beyond legacy architecture and easily
                leverage modern features to get a competitive advantage.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Faster adoption of new technology without training or
                  upskilling
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your team is familiar with MS Teams, so they can learn all the
                attributes of raising questions, getting answers, and solving
                problems.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>In-app ticket creation</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You can use MS Teams to create a ticket for a critical issue and
                easily get human assistance without logging in and out of your
                traditional ITSM platform.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>
                  Unified collaboration and coordination for service desk tasks
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With Workativ, you can keep track of service desk history in one
                place inside MS Teams, allowing you to prioritize tickets and
                address problems at speed.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Data-driven insights for collaboration improvement</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ lets you harness rich insights from the platform. This
                is a treasure trove for your service desk teams to analyze
                loopholes and make improvements to future collaborations and
                solve problems steadily.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Self-service augmentation</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The Knowledge AI integration turns knowledge bases into
                ChatGPT-like responses. As a result, your people get
                consolidated, accurate, and contextual information to solve
                their problems, while saving time to get back to work faster.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Employee engagement and satisfaction</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT or LLM-driven answers solve common problems at scale,
                removing the need to connect to service desks and wait for your
                turn. The swift information delivery satisfies employees and
                encourages them to adopt self-service at scale.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Agent flexibility and convenience</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ prioritizes agent flexibility by allowing them to use a
                built-in shared live inbox that combines all ticket history into
                one single pane and pinpoints the high-priority tickets, tickets
                in queue, open tickets, and closed tickets. Besides, AI
                suggestions help them answer employees quickly and in real-time.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>Compliance with the shift-left strategy</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Thanks to the hyper-automation of self-service and the
                integration of Knowledge AI, Workativ accelerates knowledge
                search and reduces time to mitigate issues using knowledge bases
                at Level 0. With no human agents needed, Workativ helps you
                comply with the shift-left strategy and save costs.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small color-black pl-4 mb-2">
                <li>XLAs for ITIL compliance</li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ improves data-driven automation and analytics, giving
                in-depth views into ITSM performance and opportunities for
                improving existing workflows. As a result, issues are less
                repetitive, frequent, and severe, fostering proactive service
                desks and transitioning to an XLA approach.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Start your employee experience journey with Workativ and MS
                Teams
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ has all-encompassing features that help you build a
                comprehensive and powerful collaboration and engagement
                ecosystem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is easy and fast for you to turn MS Teams into a familiar
                platform where your team members can connect seamlessly and
                effortlessly to find answers for common to complex problems,
                solve them autonomously, and get back to work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of it, MS Teams also lets you apply LLMs to search
                queries and find contextual answers in real-time to reduce
                downtime in minimal time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, you can build a powerful collaboration and
                engagement platform for employee support and effectively harness
                the power of Generative AI for ultimate benefits. Overall, you
                can foster employee experience and foster your company’s growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you need assistance in building your GenAI-powered
                collaboration and engagement tool with MS Teams, Workativ can
                help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule your demo today.{" "}
                </a>
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. How can you extend the collaboration and engagement
                capabilities for your employees on MS Teams?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams is a powerful collaboration and engagement tool. You
                normally use it to share files, chat, meet with teams, and share
                group messages, among other things.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, you can also use MS Teams to extend automation for an
                expandable level of collaboration and engagement to improve the
                employee experience by integrating{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  a conversational AI platform like Workativ
                </span>{" "}
                powered by Generative AI. This allows MS Teams to offer its
                users amazing features to find information, do their best work,
                and seek human assistance for critical issues.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How does Workativ help with change initiatives for digital
                transformation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital transformation entails introducing enterprise tools for
                workers to allow them to work efficiently. But change fatigue is
                a new concern. Workativ can help with change initiatives with
                its intuitive interface, which is easy to use for enterprise
                collaboration, engagement, and problem-solving.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Does building MS Teams with a conversational AI platform like
                Workativ involve developer assistance?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code SaaS platform, allowing you to work around
                its intuitive interface to build workflows and deploy them to MS
                Teams through integration. Using DIY features, you can easily
                start your employee experience journey on Workativ and turn MS
                Teams into a powerhouse of employee collaboration and engagement
                driven by GenAI. Hence, it requires no developer help and
                involves high costs.
              </p>
              <h3 className="font-section-sub-header-small">
                4. What are three significant use cases of MS Teams for
                collaboration and engagement other than usual usage?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When combined with Workativ and its GPT platform Knowledge AI,
                MS Teams helps boost information discovery, thus automating
                collaboration and engagement efficiently. These use cases
                include resolving password resets, leave applications, and app
                access requests among others.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience"
              className="font-section-normal-text-testimonials"
            >
              Help Desk Automation: Drive Employee Experience (2023)
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-automation-virtual-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              How to use Generative AI and Automation to Improve your Virtual
              Enterprise Employee Service Desk
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-it-support-documentation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How Generative AI Can Help You Improve Employee IT Support
              Documentation
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
